<template>
  <div class="p-3">
    <div class="row">
      <div class="col-xl-9 col-lg-9 col-md-9 col-12 h-100">
        <appProgressSteps
          :shId="shId"
          :propsData="gettStepId"
        ></appProgressSteps>
      </div>
      <div class="col-xl-3 col-lg-3 col-md-3 col-12 h-100">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12 text-center">
                <img
                  src="../../assets/img/cv-screen-marketing-1.jpeg"
                  style="height: 77px"
                  class=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <section v-if="gettStepId == 1">
      <appStep1 :mode="mode" :way="way" :shId="shId"></appStep1>
    </section>
  </div>
</template>
<script>
import appStep1 from "../../components/policeCheck/step1.vue";

import appProgressSteps from "../../components/policeCheck/progressSteps.vue";
import * as authService from "../../services/auth.service";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    appStep1,
    appProgressSteps,
  },
  computed: {
    ...mapGetters(["gettStepId", "getPoliceCheckSingle"]),
  },
  data() {
    return {
      mode: "new",
      way: "account",
      loginUserObj: {},
      shId: 0,
    };
  },
  methods: {
    ...mapActions(["fetchStepId", "fetchPoliceCheckSingle"]),

    /**
     * Get Stake holder id for user/applicant belongs to particular group
     * sh_id (stake holder)
     * 1 Individual (Just individual user) - role_id == 3
     * 2 Partner (Partner / Staff / Partner Customer) - role_id == 6, role_id == 7, role_id == 8
     * 3 Corporate (Corporate Manager/ Staff / Customer) - role_id == 4, role_id == 5, role_id == 9
     */
    fnCheckShId() {
      if (this.loginUserObj.role_id == 3) {
        this.shId = 1;
      }

      if (
        this.loginUserObj.role_id == 6 ||
        this.loginUserObj.role_id == 7 ||
        this.loginUserObj.role_id == 8
      ) {
        this.shId = 2;
      }

      if (
        this.loginUserObj.role_id == 4 ||
        this.loginUserObj.role_id == 5 ||
        this.loginUserObj.role_id == 9
      ) {
        this.shId = 3;
      }
    },
  },
  created() {
    this.fetchStepId(1);
    this.loginUserObj = authService.getUserFromToken();
    this.fnCheckShId();
  },
};
</script>
